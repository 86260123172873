import {
  Button,
  Box,
  Center,
  Stack,
  SimpleGrid,
  Text,
  Heading,
  useToast,
  Flex,
  Image,
  Link,
  Divider
} from "@chakra-ui/react";
import { Giveaway } from "../interfaces/Giveaways";


interface PastGiveawaysProps {
  giveaways: Giveaway[];
}


export default function PastGiveaways({ giveaways }: PastGiveawaysProps) {
  const toast = useToast();
 
  return (
    <Box mt={12}>
      <Heading>Past Giveaways</Heading>
      <SimpleGrid columns={[1, null, 3]} spacing="40px">
        {Array.isArray(giveaways)
          ? giveaways.map((giveaway: Giveaway) => (
              <Center py={6} key={giveaway.id}>
                <Stack
                  borderWidth="1px"
                  borderRadius="lg"
                  w={{ sm: "340px", md: "540px" }}
                  height={{ sm: "476px", md: "45rem" }}
                  direction={{ base: "column", md: "column" }}
                  //bg={useColorModeValue('white', 'gray.900')}
                  boxShadow={"2xl"}
                  padding={4}
                >
                  <Flex flex={1} justifyContent={'center'}>
                    <Image objectFit="contain" boxSize={"100%"} width={"400px"} height={"400px"} src={giveaway.imageUrl} />
                  </Flex>
                  <Stack
                    flex={1}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    pt={2}
                  >
                    <Heading fontSize={"2xl"} fontFamily={"body"}>
                      {giveaway.title}
                    </Heading>
                    <Text fontWeight={600} color={"gray.500"} size="sm" mb={4}>
                      Ended: {new Date(giveaway.endTime).toLocaleString()}
                    </Text>
                    <Stack
                      align={"center"}
                      justify={"center"}
                      direction={"row"}
                      mt={6}
                    ></Stack>
                    <Stack
                      width={"340px"}
                      mt={"2rem"}
                      direction={"row"}
                      padding={2}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Button
                        flex={1}
                        fontSize={"sm"}
                        rounded={"full"}
                        colorScheme={"green"}
                        onClick={() =>  toast({
                                title: `Winner: ${giveaway.winner?.substring(0,5) + "..." + giveaway.winner?.substring(37,42)}`,
                                status: "success",
                                duration: 3000,
                                isClosable: true,
                              })}
                      >
                        View winner
                      </Button>
                    </Stack>
                    <Flex>
                      <Button m={2} variant={"ghost"}>
                        <Link
                          href={`https://opensea.io/${giveaway.winner}/takin-shotsnft?search%5BsortBy%5D=LAST_SALE_PRICE`}
                          isExternal
                        >
                          OS
                        </Link>
                      </Button>
                      <Divider orientation="vertical"></Divider>
                      <Button m={2} variant={"ghost"}>
                        <Link
                          href={`https://x2y2.io/user/${giveaway.winner}/items?contract=1_0x590e031ddf1d9e6413712916a49e1046d12c7959`}
                          isExternal
                        >
                          X2
                        </Link>
                      </Button>
                    </Flex>
                  </Stack>
                </Stack>
              </Center>
            ))
          : null}
      </SimpleGrid>
    </Box>
  );
}