import * as React from "react";
import MyRouter from "./routers/index";
import {
  ChakraProvider,
  theme,
} from "@chakra-ui/react"

declare global {
  interface Window{
    ethereum?:any
    web3?:any
  }
}

export const App = () => (
  <>  
  <ChakraProvider theme={theme}>
    <MyRouter />
  </ChakraProvider>
  </>
)
