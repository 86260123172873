import React, { ReactNode, useEffect, useState } from "react";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Icon,
  useColorModeValue,
  Link,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  BoxProps,
  FlexProps,
  Tooltip,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";
import { Avatar, AvatarGroup, Wrap, WrapItem } from "@chakra-ui/react";
import {
  FiHome,
  FiVideo,
  FiPlay,
  FiMenu,
  FiMail,
  FiGift,
  FiTag,
} from "react-icons/fi";
import { IconType } from "react-icons";
import { ReactText } from "react";
import Alchemy from "../model/Alchemy";
import getNFTs from "../interfaces/getNFTs";

interface LinkItemProps {
  name: string;
  icon: IconType;
  href: string;
}
const LinkItems: Array<LinkItemProps> = [
  { name: "Home", icon: FiHome, href: "/" },
  { name: "Library", icon: FiPlay, href: "/library" },
  { name: "Live", icon: FiVideo, href: "/live" },
  { name: "Newsletter", icon: FiMail, href: "/newsletter" },
  { name: "Giveaways", icon: FiGift, href: "/giveaways" },
  { name: "Shop", icon: FiTag, href: "/shop" },
];

export default function Nav({ children }: { children: ReactNode }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [imageUrl, setImageUrl] = useState<string>(
    "https://i.seadn.io/gcs/files/c129cd99e11dd6c15faa78f8320eb817.png?auto=format&w=256"
  );
  const [imageName, setImageName] = useState<string>("Takin Shots");
  const [isHolderOfCollection, setIsHolderOfCollection] =
    useState<boolean>(false);
  const [nfts, setNfts] = useState<getNFTs>();

  const accountsChanged = async () => {
    if (typeof window.ethereum._state.accounts[0] === "undefined") {
      //This is a disconnect
      console.info("disconnected wallet");

      const storage = require("lscache");
      storage.flush();
      window.location.replace("/");
    } else if (typeof window.ethereum._state.accounts[0] !== "undefined") {
      console.info("Connected wallet...");
      if (window.ethereum._state.accounts.length !== 0) {
        const _Alchemy = new Alchemy(window.ethereum._state.accounts[0]);
        const nft: getNFTs = await _Alchemy.getNFT();
        if (typeof nft.ownedNfts[0] !== "undefined") {
          setNfts(nft);
          setImageUrl(nft.ownedNfts[0].media[0].gateway);
          setImageName(nft.ownedNfts[0].metadata.name);
          setIsHolderOfCollection(true);
        }
      }
    }
  };

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", accountsChanged);

      window.ethereum
        .request({ method: "eth_accounts" })
        .then((ethereumAddress: string) => {
          if (ethereumAddress.length !== 0) {
            const _Alchemy = new Alchemy(ethereumAddress[0]);
            _Alchemy.getNFT().then((nft: getNFTs) => {
              if (typeof nft.ownedNfts[0] !== "undefined") {
                setNfts(nft);
                setImageUrl(nft.ownedNfts[0].media[0].gateway);
                setImageName(nft.ownedNfts[0].metadata.name);
                setIsHolderOfCollection(true);
              }
            });
          }
        });
    }
  }, []);

  useEffect(() => {}, [imageUrl, isHolderOfCollection, imageName]);

  return (
    <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <SidebarContent
        nfts={nfts}
        isHolderOfCollection={isHolderOfCollection}
        imageUrl={imageUrl}
        imageName={imageName}
        onClose={() => onClose}
        display={{ base: "none", md: "block" }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent
            nfts={nfts}
            isHolderOfCollection={isHolderOfCollection}
            imageName={imageName}
            imageUrl={imageUrl}
            onClose={onClose}
          />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
}

interface SidebarProps extends BoxProps {
  nfts: getNFTs | undefined;
  isHolderOfCollection: boolean;
  imageUrl: string;
  imageName: string;
  onClose: () => void;
}

const SidebarContent = ({
  nfts,
  isHolderOfCollection,
  imageUrl,
  imageName,
  onClose,
  ...rest
}: SidebarProps) => {
  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      borderRight="1px"
      borderRightColor={useColorModeValue("gray.200", "gray.700")}
      w={{ base: "full", md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="20" alignItems="center" mx="8" justifyContent="space-evenly">
        <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          takinshots.io
        </Text>
        <ColorModeSwitcher />
        <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} />
      </Flex>
      {isHolderOfCollection === true ? (
        <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
          <Wrap>
            <WrapItem alignItems={"center"}>
              {typeof nfts?.ownedNfts !== "undefined" &&
              nfts?.ownedNfts.length > 1 ? (
                <AvatarGroup size="md" max={3}>
                  {nfts?.ownedNfts.map((nft) => (
                    <Tooltip
                      bg="red.600"
                      closeDelay={300}
                      hasArrow
                      placement="right-end"
                      label={nft.metadata.name}
                      aria-label={nft.metadata.name}
                    >
                      <Avatar
                        name={nft.metadata.name}
                        src={nft.media[0].gateway}
                      />
                    </Tooltip>
                  ))}
                </AvatarGroup>
              ) : (
                <Tooltip
                  bg="red.600"
                  closeDelay={1000}
                  placement="right-end"
                  hasArrow
                  label={nfts?.ownedNfts[0].metadata.name}
                  aria-label={nfts?.ownedNfts[0].metadata.name}
                >
                  <Avatar
                    size="xl"
                    name={nfts?.ownedNfts[0].metadata.name}
                    src={nfts?.ownedNfts[0].media[0].gateway}
                  />
                </Tooltip>
              )}
            </WrapItem>
          </Wrap>
        </Flex>
      ) : null}
      {LinkItems.map((link) => (
        <NavItem
          href={link.href}
          key={link.name}
          icon={link.icon}
          fontFamily={"Inter"}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

interface NavItemProps extends FlexProps {
  href: string;
  icon: IconType;
  children: ReactText;
}
const NavItem = ({ href, icon, children, ...rest }: NavItemProps) => {
  return (
    <Link
      href={href}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
    >
      <Flex
        align="center"
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          bg: "purple.500",
          color: "white",
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg={useColorModeValue("white", "gray.900")}
      borderBottomWidth="1px"
      borderBottomColor={useColorModeValue("gray.200", "gray.700")}
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text fontSize="2xl" ml="8" fontFamily="monospace" fontWeight="bold">
        takinshots.io
      </Text>
    </Flex>
  );
};
