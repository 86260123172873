import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PrivateRoute from "../auth/PrivateRoute";
import { UserContext } from "../context/userContext";
import { checkUser } from "../auth/wallet";
import { Page } from "./types";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import Disclaimer from "../components/Disclaimer";
import PageHome from "../pages/home";
import Library from "../pages/library";
import Live from "../pages/live";
import Connect from "../pages/connect";
import Newsletter from "../pages/newsletter";
import { UserData } from "../interfaces/UserData";
import Giveaways from "../pages/giveaways";
import Shop from "../pages/shop";

export const pages: Page[] = [
  { path: "/", component: PageHome, isSecure: false },
  { path: "/connect", component: Connect, isSecure: false },
  { path: "/library", component: Library, isSecure: true },
  { path: "/live", component: Live, isSecure: true },
  { path: "/newsletter", component: Newsletter, isSecure: false },
  { path: "/giveaways", component: Giveaways, isSecure: false },
  { path: "/shop", component: Shop, isSecure: false },
];

const MyRoutes = () => {
  const [user, setUser] = useState<UserData>({
    isLoggedIn: null,
    address: "",
    isHolderOfCollection: false,
    ts: "",
  });

  useEffect(() => {
    const validateUser = async () => {
      try {
        await checkUser(setUser);
      } catch (error) {
        console.error(error);
      }
    };
    validateUser();
  }, []);

  return (
    <UserContext.Provider value={user}>
      <BrowserRouter
        basename={process.env.NODE_ENV === "production" ? "/" : "/"}
      >
        <Nav>
          <Routes>
            {pages.map(({ component, path, isSecure }) => {
              const Component = component;

              if (isSecure === true && user.isLoggedIn === false) {
                return (
                  <Route
                    key={path}
                    element={
                      <PrivateRoute
                        key={path}
                        component={<Component />}
                        path={path}
                      />
                    }
                    path={path}
                  />
                );
              }

              return <Route key={path} element={<Component />} path={path} />;
            })}
          </Routes>
          <Disclaimer></Disclaimer>
          <Footer></Footer>
        </Nav>
      </BrowserRouter>
    </UserContext.Provider>
  );
};

export default MyRoutes;
