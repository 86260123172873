import React from "react";
import { Heading} from "@chakra-ui/react";
import { Grid, GridItem } from "@chakra-ui/react";
import {  Card, CardBody} from "reactstrap";

function Live() {

  return (
    <div>
      <Heading>Live Broadcast</Heading>
      <Card align="center">
        <CardBody>
          <Grid>
            <GridItem>
            <iframe
                title="Takin Shots Live"
                src="https://vimeo.com/event/2728581/embed"
                allowFullScreen={true}
                frameBorder="3"
                height= '505'
                style={{width: '100%'}}
              ></iframe>
            </GridItem>
          </Grid>
          <Grid>
            <GridItem>
            <iframe
                title="Takin Shots Chat"
                src="https://vimeo.com/event/2728581/chat/"
                height= '505'
                style={{width: '100%'}}
                frameBorder="0"
              ></iframe>
            </GridItem>
          </Grid>
        </CardBody>
      </Card>
    </div>
  );
}
export default Live;