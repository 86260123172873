import {
  Container,
  SimpleGrid,
  Image,
  Flex,
  Heading,
  Text,
  Stack,
  StackDivider,
  useColorModeValue,
} from '@chakra-ui/react';
// import {
//   IoAnalyticsSharp,
//   IoLogoBitcoin,
//   IoSearchSharp,
// } from 'react-icons/io5';
import { ReactElement } from 'react';
import sneakPeak from './imgs/takinshot-preview.png'

interface FeatureProps {
  text: string;
  iconBg: string;
  icon?: ReactElement;
}

const Feature = ({ text, icon, iconBg }: FeatureProps) => {
  return (
    <Stack direction={'row'} align={'center'}>
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        bg={iconBg}>
        {icon}
      </Flex>
      <Text fontWeight={600}>{text}</Text>
    </Stack>
  );
};

export default function Future() {
  return (
    <Container maxW={'5xl'} py={12}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Stack spacing={4}>
          <Text
            textTransform={'uppercase'}
            color={'orange.400'}
            fontWeight={600}
            fontSize={'sm'}
            bg={useColorModeValue('orange.50', 'orange.900')}
            p={2}
            alignSelf={'flex-start'}
            rounded={'md'}>
            The Future
          </Text>
          <Heading>There will also be forever evolving benefits to being a pass holder, including IRL benefits.</Heading>
          <Text color={'gray.500'} fontSize={'lg'}>
           We will be dropping a badass Podcast and Youtube channel that will continue to grow, covering trading and techniques, education, the truth behind trading, tips secrets and more. There will be weekly episodes and vlog style videos of behind the scenes to life and more!
          </Text>
          <Text>
            Now, you may ask, why the f*** would anyone do this?
          </Text>
          <Stack
            spacing={4}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.100', 'gray.700')}
              />
            }>
            <Feature
            //   icon={
            //     //<Icon as={IoAnalyticsSharp} color={'yellow.500'} w={5} h={5} />
            //   }
              iconBg={useColorModeValue('yellow.100', 'yellow.900')}
              text={'I honestly don\'t know.'}
            />
            <Feature
            //   icon={<Icon as={IoLogoBitcoin} color={'green.500'} w={5} h={5} />}
              iconBg={useColorModeValue('green.100', 'green.900')}
              text={'Provide a TRULY, unique risk free platform.'}
            />
            <Feature
            //   icon={
            //     <Icon as={IoSearchSharp} color={'purple.500'} w={5} h={5} />
            //   }
              iconBg={useColorModeValue('purple.100', 'purple.900')}
              text={'Build a badass community.'}
            />
          </Stack>
        </Stack>
        <Flex>
          <Image
            rounded={'md'}
            alt={'feature image'}
            src={
              sneakPeak
            }
            objectFit={'cover'}
            loading='lazy'
          />
        </Flex>
      </SimpleGrid>
    </Container>
  );
}
