import About from "../components/About";
import Future from "../components/Future";
import Hero from "../components/Hero";
import NFT from "../components/NFT";
import Benefits from "../components/Benefits";
import BuyNow from "../components/BuyNow";
import ShotScore from "../components/ShotScore";

function PageHome() {
  return (
    <div>
      <Hero></Hero>
      <About></About>
      <NFT></NFT>
      <Future></Future>
      <Benefits></Benefits>
      <ShotScore></ShotScore>
      <BuyNow></BuyNow>
    </div>
  );
}
export default PageHome;