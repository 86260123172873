import Axios from "axios";
import getNFTs from "../interfaces/getNFTs";
var CryptoJS = require("crypto-js");

export default class Alchemy {
  wallet: string;
  isHolderOfCollection: boolean;
  url: string;
  storage: any;

  constructor(wallet: string) {
    this.wallet = wallet;
    this.isHolderOfCollection = false;
    this.url = `https://eth-mainnet.g.alchemy.com/nft/v2/${process.env.REACT_APP_ALCHEMY_ACCESS}/isHolderOfCollection?wallet=${this.wallet}&contractAddress=${process.env.REACT_APP_GRANNY_ADDRESS}`;
    this.storage = require("lscache");
  }
  checkIsHolderOfCollection = async () => {
    const resp = await Axios.get(this.url);
    this.isHolderOfCollection = resp.data.isHolderOfCollection;
    return resp.data.isHolderOfCollection;
  };
  getNFT = async (): Promise<getNFTs> => {
    //Check if the NFTs are in the cache
    let nfts = this.storage.get(this.wallet);

    //Make sure the cache isn't null, if it is load it from API
    if(nfts === null){
        const resp = await Axios.get(`https://eth-mainnet.g.alchemy.com/nft/v2/${process.env.REACT_APP_ALCHEMY_ACCESS}/getNFTs?owner=${this.wallet}&contractAddresses[]=${process.env.REACT_APP_GRANNY_ADDRESS}`);
        nfts = resp.data;
        //Cache it
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(nfts), this.wallet).toString();
        this.storage.set("NFT-" + this.wallet, ciphertext, 25);
    }else{
        var bytes  = CryptoJS.AES.decrypt(nfts, this.wallet);
        nfts = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }

    return nfts;
  };
  get = () => {
    return this.storage.get("NFT-" + this.wallet);
  };
  set = () => {
    return this.storage.set("NFT-" + this.wallet, this.isHolderOfCollection, 5);
  };
  remove = () => {
    this.storage.remove("NFT-" + this.wallet);
  };
  exists = () => {
    const obj = this.storage.get("NFT-" + this.wallet);
    if (obj !== null) {
      return true;
    }
    return false;
  };
}