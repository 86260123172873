import {
  Box,
  Container,
  Stack,
  Text,
  Image,
  Flex,
  VStack,
  Heading,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
  List,
  ListItem
} from '@chakra-ui/react';

import sneakPeak from './imgs/takinshots-preview2.png'

export default function NFT() {
  return (
    <Container maxW={'7xl'}>
      <SimpleGrid
        columns={{ base: 1, lg: 2 }}
        spacing={{ base: 8, md: 10 }}
        py={{ base: 18, md: 24 }}>
        <Flex>
          <Image
            rounded={'md'}
            alt={'product image'}
            src={
              sneakPeak
            }
            fit={'fill'}
            align={'center'}
            w={'100%'}
            h={{ base: '100%', sm: '400px', lg: '500px' }}
            loading='lazy'
          />
        </Flex>
        <Stack spacing={{ base: 6, md: 10 }}>
          <Box as={'header'}>
            <Heading
              lineHeight={1.1}
              fontWeight={800}
              fontSize={{ base: '2xl', sm: '4xl', lg: '5xl' }}
              bgClip="text"
              bgGradient="linear(to-r, orange.400,purple.500)">
              444 Badass Grandmas
            </Heading>
            <Text
              color={useColorModeValue('gray.900', 'gray.400')}
              fontWeight={300}
              fontSize={'2xl'}>
              Living on the blockchain, here to make a statement. Mint was 11/22/2022 @ 11:22 A.M. Mint was free and airdropped to hand selected WL.
            </Text>
          </Box>

          <Stack
            spacing={{ base: 4, sm: 6 }}
            direction={'column'}
            divider={
              <StackDivider
                borderColor={useColorModeValue('gray.200', 'gray.600')}
              />
            }>
            <VStack spacing={{ base: 4, sm: 6 }}>
              <Text
                color={useColorModeValue('gray.500', 'gray.400')}
                fontSize={'2xl'}
                fontWeight={'300'}>
                Since mint we have just been straight COOKIN profits and that is what our goal is as an Alpha Trading Community. We focus on all market sectors Crypto, NFTs, Stocks (even YEARS of experience in trading luxury assets if you should be interested)
              </Text>
            </VStack>
            <Box>
              <Text
                fontSize={{ base: '16px', lg: '18px' }}
                color={useColorModeValue('purple.500', 'purple.300')}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}>
                Our Goal
              </Text>
              <Text
                color={useColorModeValue('gray.900', 'gray.400')}
                fontSize={'2xl'}
                fontWeight={'300'}>
                While our main goal is to churn out profits, as the Founder of the community my focus is to shorten the learning curve for people to enter the trading space. While there is a lot of information online on how to trade, putting it to use in real life situations can be “intimidating” and uncomfortable alone. We encourage ALL levels of expertise, whether you never traded or you have millions in profits. From getting your feet wet to fully understanding the markets is our goal. I don't want people to copy, I want people to KNOW HOW! Growing together, the more eyes creating confidence on our trades, trading in-sync and cashing in together is a special movement we will be making. Now this means nothing coming from a founder- so just ask community members for yourself!
              </Text>
              {/* <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                <List spacing={2}>
                  <ListItem>Access to exclusive Discord alpha channels.</ListItem>
                  <ListItem>Auto-entered to giveaways.</ListItem>{' '}
                  <ListItem>IRL benefits.</ListItem>
                </List>
                <List spacing={2}>
                  <ListItem>Collabs.</ListItem>
                  <ListItem>1-5% of profits given back to the community.</ListItem>
                  <ListItem>& much more!</ListItem>
                </List>
              </SimpleGrid> */}
            </Box>
            <Box>
              <Text
                fontSize={{ base: '16px', lg: '18px' }}
                color={useColorModeValue('purple.500', 'purple.300')}
                fontWeight={'500'}
                textTransform={'uppercase'}
                mb={'4'}>
                WHEN?
              </Text>

              <List spacing={2}>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                    MINT DATE:
                  </Text>{' '}
                   SOLD OUT - Nov. 22nd, 2022
                </ListItem>
                <ListItem>
                  <Text as={'span'} fontWeight={'bold'}>
                    PRICE:
                  </Text>{' '}
                  Free
                </ListItem> 
              </List>
            </Box>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent={'center'}>
                      
          </Stack>
        </Stack>
      </SimpleGrid>
    </Container>
  );
}
