import { useState, useEffect, useRef, useContext } from "react";
import { UserContext } from "../context/userContext";
import {
  Badge,
  Box,
  Flex,
  HStack,
  useColorModeValue,
} from "@chakra-ui/react";
import Alchemy from "../model/Alchemy";
import getNFTs from "../interfaces/getNFTs";

import PastGiveaways from "../components/PastGiveaways";
import ActiveGiveawaysGrid from "../components/ActiveGiveawaysGrid";

function Giveaways() {
  const user = useContext(UserContext);
  const [shotScore, setShotScore] = useState<number>(0);
  const [activeGiveaway, setActiveGiveaway] = useState([]);
  const [expiredGiveaway, setExpiredGiveaway] = useState([]);
  const isMounted = useRef(false);

  // Fetch NFTs in wallet and calculate total shot score
  useEffect(() => {
    if (isMounted.current) {
      let score: number = 0;

      const _Alchemy = new Alchemy(user.address);
      _Alchemy.getNFT().then((nft: getNFTs) => {
        if (typeof nft.ownedNfts[0] !== "undefined") {
          for (let i = 0; i < nft.ownedNfts.length; i++) {
            for (
              let j = 0;
              j < nft.ownedNfts[i].metadata.attributes.length;
              j++
            ) {
              if (
                nft.ownedNfts[i].metadata.attributes[j]?.trait_type ===
                "Shots Score"
              ) {
                score += nft.ownedNfts[i].metadata.attributes[j].value;
                setShotScore(score);
              } else {
                score += 0;
              }
            }
          }
        }
      });
    } else {
      isMounted.current = true;
    }
  }, [user]);

  // Fetch giveaways
  useEffect(() => {
    async function getActiveGiveaways() {
      const activeGiveaways = await fetch(`/api/active`)
        .then((res) => res.json());

      setActiveGiveaway(activeGiveaways);
    }

    getActiveGiveaways();
  }, []);

  useEffect(() => {
    async function getExpiredGiveaways() {
      const expiredGiveaways = await fetch(`/api/expired`)
      .then((res) => res.json());

      setExpiredGiveaway(expiredGiveaways);
    }

    getExpiredGiveaways();
  }, []);

  return (
    <div>
      {/* User Context */}
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={0}>
        <Flex h={14} alignItems={"center"} justifyContent={"space-evenly"}>
          <HStack spacing={2} alignItems={"center"}>
            <Box>
              <Badge ml="1" fontSize="0.9em" colorScheme={"orange"}>
                logged in as:{" "}
                {user.address?.substring(0, 4) +
                  "..." +
                  user.address?.substring(38, 42)}
              </Badge>{" "}
            </Box>
          </HStack>
          <Flex alignItems={"center"}>
            <Box>
              <Badge ml="1" fontSize="0.9em" colorScheme={"purple"}>
                Total Shot Score: {shotScore}
              </Badge>
            </Box>
          </Flex>
        </Flex>
      </Box>

      {/* Giveaways Heading */}
      {/* <HStack justifyContent={"space-between"} mb={4} mt={10}>
        <Heading>Active Giveaways</Heading>
      </HStack> */}

      {/* Active Giveaways Carousel */}
      <ActiveGiveawaysGrid giveaways={activeGiveaway} />

      {/* Expired Giveaways */}
      <PastGiveaways giveaways={expiredGiveaway} />
    </div>
  );
}

export default Giveaways;