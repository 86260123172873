
import Auth from '../model/Auth';

export const checkUser = async (cb) => {
  

  let isLoggedIn = false;
  let address = "";
  let isHolderOfCollection = false;
  const ts = new Date();

  //Check they have ethereum in the browser
  if (window.ethereum) {


    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });

    //console.info("window.ethereum._state.accounts", window.ethereum._state.accounts);
    //check if their wallet is connected
    if (accounts.length === 0) {
      // console.info("window.ethereum._state", window.ethereum._state);
      //Since they aren't connected they need to be pushed to /connect
      return cb({
        isLoggedIn,
        address,
        isHolderOfCollection,
        ts,
      });
    }



    //Load the wallet
    const _auth = new Auth(accounts);

    //Grab the wallet address
    address = _auth.getWallet();

    //Check if they are a holder of the collection
    isHolderOfCollection = await _auth.checkUser();

    if(isHolderOfCollection){
      isLoggedIn = true;
     // console.info("isLoggedIn: ", isLoggedIn);
      await _auth.getNFT();
    }

    //console.info({isLoggedIn, address, isHolderOfCollection, ts});

    return cb({ isLoggedIn, address, isHolderOfCollection, ts });
  } else {
    console.info("Non-Ethereum browser detected. You should consider trying MetaMask!: ");
  }
  console.info({isLoggedIn, address, isHolderOfCollection, ts});
  return cb({ isLoggedIn, address, isHolderOfCollection, ts });
};