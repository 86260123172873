import {
  Box,
  Center,
  Stack,
  SimpleGrid,
  Heading,
  Flex,
  Image,
  Button
} from "@chakra-ui/react";
import { Giveaway } from "../interfaces/Giveaways";

interface PastGiveawaysProps {
  giveaways: Giveaway[];
}

export default function ActiveGiveawaysGrid({ giveaways }: PastGiveawaysProps) {
  return (
    <Box mt={12}>
      <Heading>Active Giveaways</Heading>
      <SimpleGrid columns={[1, null, 3]} spacing="40px">
        {Array.isArray(giveaways)
          ? giveaways.map((giveaway: Giveaway) => (
              <Center py={6} key={giveaway.id}>
                <Stack
                  borderWidth="1px"
                  borderRadius="lg"
                  w={{ sm: "340px", md: "540px" }}
                  height={{ sm: "476px", md: "45rem" }}
                  direction={{ base: "column", md: "column" }}
                  //bg={useColorModeValue('white', 'gray.900')}
                  boxShadow={"2xl"}
                  padding={4}
                >
                  <Flex flex={1} justifyContent={"center"}>
                    <Image
                      objectFit="contain"
                      boxSize={"100%"}
                      width={"400px"}
                      height={"400px"}
                      src={giveaway.imageUrl}
                    />
                  </Flex>
                  <Stack
                    flex={1}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    p={1}
                    pt={2}
                  >
                    <Heading fontSize={"xl"} fontFamily={"body"} mb={10}>
                      {giveaway.title}
                    </Heading>
                    <Button colorScheme="teal" rounded='full'>
                    
                      Ends: {new Date(giveaway.endTime).toLocaleString()}
                    
                    </Button>
                    <Stack
                      align={"center"}
                      justify={"center"}
                      direction={"row"}
                      mt={6}
                    ></Stack>
                    <Stack
                      width={"340px"}
                      mt={"2rem"}
                      direction={"row"}
                      padding={2}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                    </Stack>
                  </Stack>
                </Stack>
              </Center>
            ))
          : null}
      </SimpleGrid>
    </Box>
  );
}
