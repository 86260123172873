import axios from "axios";

export default class StripeUtils {
  domain: string;
  stripeURL: string;
  Authorization: string;
  ContentType: string;

  constructor(domain: string) {
    this.domain = domain;
    this.stripeURL = "https://api.stripe.com/v1";
    this.Authorization = `Bearer ${process.env.REACT_APP_STRIPE_SK}`;
    this.ContentType = "application/x-www-form-urlencoded";
  }
  createCheckoutSession = async (id: string) => {
    try {
      const data = {
        mode: "subscription",
        success_url: `${this.domain}/newsletter?success=true&session_id={CHECKOUT_SESSION_ID}`,
        cancel_url: `${this.domain}/newsletter?canceled=true`,
        allow_promotion_codes: true,
        line_items: [
          {
            // price: prices.data[0].id,
            price: id,
            // For metered billing, do not pass quantity
            quantity: 1,
          },
        ],
      };
      const qs = require("qs");
      const response = await axios({
        method: "post",
        url: `${this.stripeURL}/checkout/sessions`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
        data: qs.stringify(data),
      });
      return response.data.url;
    } catch (error: any) {
      console.log(error?.data.error.message);
      throw error;
    }
  };
  retrieveProduct = async (productId: string) => {
    try {
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/products/${productId}`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      return response.data;
    } catch (error: any) {
      console.log(error?.data.error.message);
      throw error;
    }
  };
  retrievePrice = async (priceId: string) => {
    try {
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/prices/${priceId}`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      return response.data;
    } catch (error: any) {
      console.log(error?.data.error.message);
      throw error;
    }
  };
  createBillingPortalSession = async (email: string) => {
    try {
      const customer = await this.searchCustomerByEmail(email);

      //Make sure the customer exists within stripe
      if (customer.length === 0) {
        //If they don't exists stop here
        return { success: false };
      }

      const response = await axios({
        method: "post",
        url: `${this.stripeURL}/billing_portal/sessions`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
        data: {
          customer: customer[0].id,
          return_url: `${this.domain}/newsletter`,
        },
      });
      return {url: response.data.url, customer: response.data.customer};
    } catch (error: any) {
      console.log(error?.data.error.message);
      throw error;
    }
  };
  searchCustomerByEmail = async (email: string) => {
    try {
      const response = await axios({
        method: "get",
        url: `${this.stripeURL}/customers?email=${email}`,
        headers: {
          Authorization: this.Authorization,
          "Content-Type": this.ContentType,
        },
      });
      return response.data.data;
    } catch (error: any) {
      console.log(error?.data.error.message);
      throw error;
    }
  };
}