import Alchemy from "./Alchemy";
var CryptoJS = require("crypto-js");

export default class Auth {
    wallet: string;
    storage: any;

    constructor(wallet: string) { 
        this.wallet = wallet;
        this.storage = require('lscache');
    };
    checkUser = async () => {
        const _Alchemy = new Alchemy(this.wallet[0]);
        const isHolder = await _Alchemy.checkIsHolderOfCollection();

        if(isHolder){
            this.set();
        }

        return isHolder
    };
    getNFT = async () => {
        const _Alchemy = new Alchemy(this.wallet[0]);
        const nft = await _Alchemy.getNFT();
        return nft
    };
    login = (wallet: string) => {
        this.wallet = wallet;
        return this.storage.set('shots', wallet[0], 5);
    };
    equals = () => {
        const storedData = this.get();
        return storedData ===  this.wallet[0];
    };
    getWallet = () => {
       return this.wallet[0];
    };
    get = () => {
        const storedData = this.storage.get('shots');

        if(storedData === null){
            return "";
        }

        var bytes  = CryptoJS.AES.decrypt(storedData, process.env.REACT_APP_BASE_KEY);
        var data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        return data;
    };
    set = () => {
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(this.wallet[0]), process.env.REACT_APP_BASE_KEY).toString();
        return this.storage.set('shots', ciphertext, 5);
    };
    remove = () => {
        this.storage.remove('shots');
    };
    exists = () => {
        const obj = this.storage.get('shots');
        if (obj !== null) {
          return true;
        } 
        return false;
      };
  }