import {
  Box,
  chakra,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from '@chakra-ui/react';

interface StatsCardProps {
  title: string;
  stat: string;
}
function StatsCard(props: StatsCardProps) {
  const { title, stat } = props;
  return (
    <Stat
      px={{ base: 4, md: 8 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor={useColorModeValue('gray.800', 'gray.500')}
      rounded={'lg'}>
      <StatLabel fontWeight={'medium'}>
        {title}
      </StatLabel>
      <StatNumber fontSize={'2xl'} fontWeight={'medium'}>
        {stat}
      </StatNumber>
    </Stat>
  );
}

export default function ShotScore() {
  return (
    <Box maxW="8xl" mx={'auto'} pt={5} px={{ base: 2, sm: 12, md: 17 }}>
      <chakra.h1
        textAlign={'center'}
        fontSize={'4xl'}
        py={10}
        fontWeight={'bold'}
        bgClip="text"
        bgGradient="linear(to-r, purple.400,orange.500)">
               
        Our giveaways are FIRE, not some cheap stuff, real money, big money and CONSTANT!
      </chakra.h1>     
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={{ base: 5, lg: 8 }}>
        <StatsCard title={'Shot Score'} stat={'Each NFT has a unique trait called a Shot Score. This score is anywhere between 1-7. Think of a Shot Score like a raffle ticket 1 Shot Score equals 1 entry,  7 Score equals 7 entries. Own multiple passes to stack your score higher and have more entries.'} />
        <StatsCard title={'Easy'} stat={'The giveaways require no effort from pass holders, besides being a holder and being delisted on exchanges to sell your pass!'} />
      </SimpleGrid>
      <chakra.h1
        textAlign={'center'}
        fontSize={'2xl'}
        py={10}
        >I love to do giveaways and thats where the project started, when I hit a trade for profits, I give it back to the community 1-5%! </chakra.h1>
    </Box>
  );
}