export default class MandrillUtils {
  mailchimpTx: any;

  constructor() {
    this.mailchimpTx = require("@mailchimp/mailchimp_transactional")(process.env.REACT_APP_MANDRILL);
  }
  run = async () => {
    try {
      return await this.mailchimpTx.users.ping();
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  email = async (text: string, email: string) => {
    try {
      const message = {
        from_email: "no-reply@takinshots.io",
        subject: "Takin Shots Newsletter Subscription Management Link",
        text,
        to: [
          {
            email,
            type: "to"
          }
        ]
      };
      const response = await this.mailchimpTx.messages.send({
        message
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
  emailHTML = async (html: string, email: string) => {
    try {
      const message = {
        from_name: "Takin Shots",
        from_email: "no-reply@takinshots.io",
        important: true,
        subject: "Takin Shots Newsletter Subscription Management Link",
        html,
        to: [
          {
            email,
            type: "to"
          }
        ]
      };
      const response = await this.mailchimpTx.messages.send({
        message
      });
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}