import { Avatar, Box, Stack, Text, useColorModeValue } from '@chakra-ui/react';
import nickalbs from './imgs/nickalbs.png'

export default function About() {
  return (
    <Stack
      bg={useColorModeValue('gray.50', 'gray.800')}
      py={16}
      px={8}
      spacing={{ base: 8, md: 10 }}
      align={'center'}
      direction={'column'}>
      <Text
        fontSize={{ base: 'xl', md: '2xl' }}
        textAlign={'center'}
        maxW={'3xl'}>
        So, I’ve been thinking about dropping a project, running "the how's," "the what's" and "the why's" all through my head. I thought of many ideas and I landed on the direction I wanted to go. I never thought I would get this immersed into the web3/trading space, but here I am.
      </Text>
      <Box textAlign={'center'}>
        <Avatar
          src={
            nickalbs
          }
          
          mb={2}
        />

        <Text fontWeight={600}>Nickalbs</Text>
        <Text fontSize={'sm'} color={useColorModeValue('gray.400', 'gray.400')}>
          Founder
        </Text>
      </Box>
    </Stack>
  );
}



