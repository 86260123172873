import {
  Box,
  Heading,
  Container,
  Text,
  Stack
} from '@chakra-ui/react';
import banner from './imgs/ts-banner2.png'

export default function Hero() {
  return (
    <>
      <header>
        <link
          href="https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap"
          rel="stylesheet"
        />
      </header>

      <Container maxW={'3xl'}>
        <Stack
          as={Box}
          textAlign={'center'}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}>
          <Heading
            fontWeight={800}
            fontSize={{ base: '6xl', sm: '6xl', md: '8xl' }}
            lineHeight={'110%'}
            fill={'whiteAlpha.100'}>
            Takin' Shots <br />
            <Text as={'span'} color={'green.400'} bgClip="text"
            bgGradient="linear(to-r, orange.400,purple.500)">
              & Givin' Back!
            </Text>
          </Heading>
          <Text color={'gray.500'}>
            NFT's, Crypto, Stocks, and MORE.
          </Text>
          <Stack
            direction={'column'}
            spacing={3}
            align={'center'}
            alignSelf={'center'}
            position={'relative'}>
            {/* <Button
              colorScheme={'green'}
              bg={'purple.400'}
              rounded={'full'}
              px={6}
              _hover={{
                bg: 'purple.500',
              }}>
              Get Started
            </Button>
            <Button variant={'link'} colorScheme={'blue'} size={'sm'} onClick={SplitWithImage}>
              Learn more
            </Button> */}
            <Box >
              <img src={banner} alt={'banner'} width={1920} loading='lazy'></img>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </>
  );
}


