import { Box, Heading, Text } from '@chakra-ui/react';
import { WarningTwoIcon } from '@chakra-ui/icons';

export default function Disclaimer() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <WarningTwoIcon boxSize={'50px'} color={'orange.300'} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        Disclaimer
      </Heading>
      <Text color={'gray.500'}>
        1) NO I AM NOT  trying to become gurus who sells dreams, courses and fluff on social media. I will show all the wins and the losses and I will not bullshit anything- again people will already back this up. 2) I AM  NOT A FINANCIAL ADVISOR AND PLEASE DO YOUR OWN RESEARCH ON ANYTHING POSTED!
      </Text>
    </Box>
  );
}
