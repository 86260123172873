import {
  AspectRatio,
  Box,
  Button,
  HStack,
  Image,
  Skeleton,
  Stack,
  StackProps,
  Text,
  useColorModeValue,
  useToast,
  Tag,
} from "@chakra-ui/react";
import { PriceTag } from "./PriceTag";
import { useState } from "react";

interface Props {
  product;
  rootProps?: StackProps;
}

export const ProductCard = (props: Props) => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const { product, rootProps } = props;
  const {
    product: { name, description, images, default_price },
    unit_amount,
    type,
  } = product;

  async function checkout(data, type) {
    let paymentType = "";
    if (type === "one_time") {
      paymentType = "payment";
    } else {
      paymentType = "subscription";
    }
    setLoading(true);
    toast({
      title: "Hold Tight! Redirecting you!",
      description: "Thank you for shopping with us.",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    try {
      const checkout = await fetch("/api/checkout", {
        method: "POST",
        body: JSON.stringify({
          line_items: [{ price: data, quantity: 1 }],
          mode: paymentType,
        }),
      }).then((res) => res.json());
      setLoading(false);
      window.location.href = await checkout.checkout_url;
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Stack spacing={{ base: "4", md: "5" }} {...rootProps}>
      <Box position="relative">
        <AspectRatio ratio={4 / 3}>
          <Image
            src={images}
            alt={name}
            draggable="false"
            fallback={<Skeleton />}
            borderRadius={{ base: "md", md: "xl" }}
          />
        </AspectRatio>
      </Box>
      <Stack>
        <Stack spacing="1">
          <Text
            fontWeight="medium"
            color={useColorModeValue("gray.700", "gray.400")}
          >
            {name}
          </Text>
          <PriceTag price={unit_amount} currency="USD" />
        </Stack>
        <HStack minH={"100px"}>
          <Text fontSize="sm" color={useColorModeValue("gray.600", "gray.400")}>
            {description}
          </Text>
        </HStack>
        <HStack>
          <Tag colorScheme="green">
            {type === "one_time" ? "One Time" : "Recurring"}
          </Tag>
        </HStack>
      </Stack>
      <Stack align="center">
        <Button
          colorScheme="blue"
          width="full"
          isLoading={loading}
          onClick={() => checkout(default_price, type)}
        >
          Buy Now
        </Button>
      </Stack>
    </Stack>
  );
};
