import {
  Button,
  useColorModeValue,
  Heading,
  Text,
  Container,
  Flex,
  Link,
  Box,
  Center,
} from '@chakra-ui/react';


export default function BuyNow() {

  return (
    <Flex pt={'12'}>
      <Container
        maxW={'lg'}
        bg={useColorModeValue('white', 'whiteAlpha.100')}
        boxShadow={'xl'}
        rounded={'lg'}
        p={6}
        >
        <Heading
          as={'h2'}
          fontSize={{ base: 'xl', sm: '2xl' }}
          textAlign={'center'}
          mb={5}>
          What are you waiting for?
        </Heading>
        <Center>
        <Box>
        <Button colorScheme={'purple'}>    
            <Link textAlign={'center'} href={'https://opensea.io/collection/takin-shotsnft'} isExternal>
            Buy Now!
            </Link>
        </Button>  
        </Box>
       </Center>
        <Text
          mt={2}
          textAlign={'center'}>
        </Text>
      </Container>
    </Flex>
  );
}