import React, {useEffect, useState} from "react";
import { Heading} from "@chakra-ui/react";
//import {Link} from "react-dom-router";
import {
  Card, 
  CardBody,
  CardHeader,
  CardFooter,
  Wrap,
  WrapItem,
  Stack,
  Text,
  Divider,
  Image,
  Link,
  Skeleton,
  useToast,
  useColorModeValue
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons'
import Axios from "axios";
import ReactPaginate from 'react-paginate';
import './pagination.css';
//import { Buffer } from 'buffer';
var moment = require('moment');

function Library() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showcase, setShowcase] = useState<any>([]);
  const [pageCount, setPageCount ] = useState<number>(0);
  const [videoCount, setVideoCount ] = useState<number>(0);
  const [per_page, setPerpage ] = useState<number>(8);
  const [currentVideoTitle, setCurrentVideoTitle] = useState<string>("");
  const toast = useToast();
  // const createBasicAuthValue = () => {
  //   return (
  //     `basic ` +
  //     Buffer.from(
  //       process.env.REACT_APP_VIMEO_CLIENT_ID +
  //         ":" +
  //         process.env.REACT_APP_VIMEO_CLIENT_SECRET
  //     ).toString("base64")
  //   );
  // };

// async function requestVimeoAccessToken(){
//     const response = await Axios({
//         url: "https://api.vimeo.com/oauth/authorize/client",
//         method: "POST",
//         headers: {
//             "Authorization": createBasicAuthValue(),
//             "Content-Type": "application/json",
//             "Accept": "application/vnd.vimeo.*+json;version=3.4"
//         },
//         data: {
//             "grant_type": "client_credentials",
//             "scope": "public"
//         }
//     }).catch(function (error) {
//         console.log(error);
//     });

//     return response;
    
//     // return {
//     //     success : response.status === 200,
//     //     data : response.data.access_token
//     // }
// }




async function getPage(path: string) {
  //const tokenResponse = await requestVimeoAccessToken();
  setIsLoading(false);
  const response = await Axios({
    url: `https://api.vimeo.com${path}`,
    method: "GET",
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
      //"Authorization": `Bearer ${tokenResponse?.data.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/vnd.vimeo.*+json;version=3.4",
    },
  }).catch(function (error) {
    console.log(error);
    toast({
      title: 'Library Error',
      description: error.message,
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  });

  //Make sure we have a new list of videos to display
  if(typeof response?.data !== "undefined"){
    setVideoCount(response?.data.total);
    setShowcase(response?.data.data);
  }else{
     //If not we need to display an alert
    toast({
      title: 'Library Page',
      description: "We couldn't find the page you requested.",
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }

  setIsLoading(true);
}

const handlePageClick = async (event: any) => {
  const path = `/users/${process.env.REACT_APP_VIMEO_USERID}/albums/${process.env.REACT_APP_VIMEO_SHOWCASE}/videos?page=${event.selected + 1}&per_page=${per_page}`
  await getPage(path);
};

function loadVideo(html: string, name: string) {
  //Set the HTML of the div with the embed HTML
  document.getElementById("video")!.innerHTML = html;
  //Grab the iFrame we just embedded
  let iFrames = document.getElementsByTagName("iframe");

  //Set the video title
  setCurrentVideoTitle(name);

  //Remove the width attribute
  iFrames[0].removeAttribute("width");
  //Update the height attribute
  iFrames[0].height = "520";
  //Set the style width attribute
  iFrames[0].style.width = "100%";
}

  useEffect(() => {

    async function requestVimeoChannel() {
      //const tokenResponse = await requestVimeoAccessToken();
    
    
      //Call the api to get the first page of videos
      const response = await Axios({
        url: `https://api.vimeo.com/users/${process.env.REACT_APP_VIMEO_USERID}/albums/${process.env.REACT_APP_VIMEO_SHOWCASE}/videos?page=1&per_page=${per_page}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_VIMEO_ACCESS_TOKEN}`,
          //"Authorization": `Bearer ${tokenResponse?.data.access_token}`,
          "Content-Type": "application/json",
          Accept: "application/vnd.vimeo.*+json;version=3.4",
        },
      }).catch(function (error) {
        console.error(error);
        toast({
          title: 'Library Error',
          description: error.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        })
      });
    
      //Make sure we have some videos
      if (typeof response?.data !== "undefined") {
    
        //Set the page count based on the per_page setting and the total videos in the library
        setPageCount(
          response?.data.total < per_page ? 1 : response?.data.total % per_page
        );
    
        //Set the total count of videos in the library
        setVideoCount(response?.data.total);
        //Set the quantity of videos per page
        setPerpage(response?.data.per_page);
        //Set the actual videos to display, which is the first page in this case
        setShowcase(response?.data.data);
    
        loadVideo(response?.data.data[0].embed.html, response?.data.data[0].name);
    
    
        //Set the HTML of the div with the embed HTML
        //document.getElementById("video")!.innerHTML = response?.data.data[0].embed.html;
        //setCurrentVideoTitle(response?.data.data[0].name);
        //Grab the iFrame we just embedded
        //let iFrames = document.getElementsByTagName("iframe");
        //Remove the width attribute
        //iFrames[0].removeAttribute("width");
        //Update the height attribute
        //iFrames[0].height = "520";
        //Set the style width attribute
        //iFrames[0].style.width = "100%";
        
      }
    
      //Set the loaded flag
      setIsLoading(true);
    }

    requestVimeoChannel();

  }, [per_page, toast]);

  return (
    <div>
      <Heading>Library</Heading>
      <Card align="center" style={{ marginTop: "1em" }} >
        <CardBody style={{ width: "100%", height: "auto" }} >
          <Skeleton isLoaded={isLoading}>
             <Heading>{currentVideoTitle}</Heading>
            <div id="video" style={{ position: "relative", width: "100%", height: "auto" }}></div>
          </Skeleton>
        </CardBody>
      </Card>
      <Card style={{ marginTop: "1em" }}>
        <CardHeader>
          <Heading size="md">Videos ({videoCount})</Heading>
        </CardHeader>
        <CardBody style={{ width: "100%", height: "auto" }}>
          <Skeleton isLoaded={isLoading} >
            <Wrap bg={useColorModeValue('white', 'whiteAlpha.100')}>
              {showcase.map((video: any, index: number) => (
                <WrapItem key={index}>
                  <Card
                    key={"elevated"}
                    maxW="sm"
                  >
                    <CardHeader>
                      <Heading size="md">
                        <Link
                          onClick={(e) => {
                            e.preventDefault();
                            loadVideo(video.embed.html, video.name);
                          }}
                        >
                          {video.name}
                        </Link>
                      </Heading>
                    </CardHeader>
                    <CardBody>
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          loadVideo(video.embed.html, video.name);
                        }}
                      >
                        <Image
                          src={video.pictures.base_link}
                          alt={video.name}
                          borderRadius="lg"
                          height={"250"}
                        />
                      </Link>
                      <Stack mt="6" spacing="3">
                        <Text>{video.description}</Text>
                        <Text color="blue.600" fontSize="1xl">
                          {moment(video.created_time).format(
                            "dddd, MMMM Do YYYY, h:mma"
                          )}
                        </Text>
                      </Stack>
                    </CardBody>
                    <Divider />
                  </Card>
                </WrapItem>
              ))}
            </Wrap>
          </Skeleton>
        </CardBody>
        <CardFooter>
            <ReactPaginate
              breakLabel="..."
              nextLabel={<ArrowRightIcon boxSize={6} />}
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel={<ArrowLeftIcon boxSize={6} />}
              renderOnZeroPageCount={undefined}
              activeClassName={"item active "}
              breakClassName={"item break-me "}
              containerClassName={"pagination"}
              disabledClassName={"disabled-page"}
              marginPagesDisplayed={2}
              nextClassName={"item next "}
              pageClassName={"item pagination-page "}
              previousClassName={"item previous"}
            />
        </CardFooter>
      </Card>
    </div>
  );
}
export default Library;