import React, { useState, useEffect, useContext } from "react";
import { useToast, useColorModeValue, Card, CardHeader, CardBody, CardFooter, Heading, Stack, Button, ButtonGroup , Text, Alert, AlertIcon, AlertTitle, AlertDescription } from "@chakra-ui/react";
import { UserContext } from "../context/userContext";
import Auth from "../model/Auth";
import { Search2Icon, ExternalLinkIcon } from "@chakra-ui/icons";

function Connect() {
  const { isHolderOfCollection } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(false);
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [connected, setConnected] = useState<boolean>(false);
  const [buttonTxt, setButtonTxt] = useState<string>("Connect Wallet");
  const [alertDescription, setAlertDescription] = useState<string>("");
  const [alertTitle, setAlertTitle] = useState<string>("");
  const [alertStatus, setAlertStatus] = useState<string>("info");
  const toast = useToast();

  const refresh = async () => {
    setRefreshing(true);
    if (typeof window.ethereum._state.accounts[0] !== "undefined") {
      const _auth = new Auth(window.ethereum._state.accounts);
      _auth.set();

      if (
        typeof window.ethereum._state.accounts[0] !== "undefined" &&
        _auth.equals() === true
      ) {
        const _auth = new Auth(window.ethereum._state.accounts);

        const isHolder = await _auth.checkUser();

        if (isHolder) {
          setAuthenticated(true);
          window.location.replace("/library");
        }
      }

      setAlertStatus("error");
      setButtonTxt("Not Authenticated");
      setAlertTitle("Non-Holder Wallet");
      setAlertDescription(
        "Your wallet must contain a Granny to be authenticated."
      );
      toast({
        title: "Not Authenticated",
        description: "Your wallet must contain a Granny to be authenticated.",
        status: "warning",
        duration: 9000,
        isClosable: true,
      });
    }

    setRefreshing(false);
  };

  const connectWallet = async () => {
    if (window.ethereum) {
      // Do something
      setIsLoading(true);

      const ethereumAddress = await window.ethereum.request({
        method: "eth_requestAccounts",
      });

      const _auth = new Auth(ethereumAddress);

      const isHolder = await _auth.checkUser();

      if (isHolder) {
        await _auth.getNFT();
      }

      setIsLoading(false);
    }
  };
  useEffect(() => {
    const accountsChanged = async () => {
      if (typeof window.ethereum._state.accounts[0] !== "undefined") {
        //console.info('Connected wallet:', window.ethereum._state.accounts[0]);

        const _auth = new Auth(window.ethereum._state.accounts);
        _auth.set();

        if (
          typeof window.ethereum._state.accounts[0] !== "undefined" &&
          _auth.equals() === true
        ) {
          setConnected(true);

          const _auth = new Auth(window.ethereum._state.accounts);

          const isHolder = await _auth.checkUser();

          if (isHolder) {
            setAuthenticated(true);
            window.location.replace("/library");
          } else {
            setAlertStatus("error");
            setButtonTxt("Not Authenticated");
            setAlertTitle("Non-Holder Wallet");
            setAlertDescription(
              "Your wallet must contain a Granny to be authenticated."
            );
            toast({
              title: "Not Authenticated",
              description:
                "Your wallet must contain a Granny to be authenticated.",
              status: "warning",
              duration: 9000,
              isClosable: true,
            });
          }

          //setButtonTxt("View Library");
          //setAlertTitle("Connected");
          //setAlertDescription("Your wallet has been connected and authenticated");
          //console.info("Your wallet has been connected and authenticated");
        } else if (typeof window.ethereum._state.accounts[0] !== "undefined") {
          setConnected(true);
          setButtonTxt("Connected");
          setAlertTitle("Connected");
          setAlertDescription("Your wallet has been connected");
          //console.info("Your wallet has been connected");
        }
      }
    };

    if (window.ethereum) {
      window.ethereum.on("accountsChanged", accountsChanged);
      //Check if an account is connected
      window.ethereum
        .request({ method: "eth_accounts" })
        .then((ethereumAddress: string) => {
          if (ethereumAddress.length !== 0) {
            //window.location.replace('/library');
            setConnected(true);

            if (isHolderOfCollection) {
              setAuthenticated(true);
              setButtonTxt("Authenticated");
              setAlertTitle("Authenticated Holder");
              setAlertDescription(
                "Your wallet has been connected and authenticated"
              );
            } else {
              setAuthenticated(false);
              setAlertStatus("error");
              setButtonTxt("Not Authenticated");
              setAlertTitle("Non-Holder Wallet");
              setAlertDescription(
                "Your wallet must contain a Granny to be authenticated."
              );
              toast({
                title: "Not Authenticated",
                description:
                  "Your wallet must contain a Granny to be authenticated.",
                status: "warning",
                duration: 9000,
                isClosable: true,
              });
            }
          }
        });
    } else {
      setAlertStatus("error");
      setAlertTitle("install metamask extension!!");
      setAlertDescription("install metamask extension!!");
    }
  }, [isHolderOfCollection, toast]);

  return (
    <div>
      <Card
        align="center"
        bg={useColorModeValue('white', 'whiteAlpha.100')}
        style={{
          marginTop: "6em",
          marginBottom: "6em",
        }}
      >
        <CardHeader>
          <Heading size="md">Connect Wallet</Heading>
        </CardHeader>
        <CardBody>
          {alertTitle !== "" ? (
            <Alert status={alertStatus === "info" ? "info" : "error"}>
              <AlertIcon />
              <AlertTitle>{alertTitle}</AlertTitle>
              <AlertDescription>{alertDescription}</AlertDescription>
            </Alert>
          ) : (
            <Text>Please connect your wallet containing your Granny(s)</Text>
          )}
        </CardBody>
        <CardFooter>
          <Stack direction="row" spacing={4}>
            {connected ? (
              authenticated ? (
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.replace("/library");
                  }}
                  size="md"
                  height="48px"
                  width="200px"
                  border="2px"
                  borderColor={connected ? "green.500" : "orange.500"}
                  colorScheme={connected ? "teal" : "orange"}
                  variant="outline"
                >
                  View Library
                </Button>
              ) : (
                <ButtonGroup variant="outline" spacing="6">
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      refresh();
                    }}
                    leftIcon={<Search2Icon />}
                    isLoading={refreshing}
                    loadingText="Checking Wallet"
                    spinnerPlacement="start"
                    size="md"
                    height="48px"
                    width="200px"
                    border="2px"
                    borderColor={"blue"}
                    colorScheme={"blue"}
                    variant="outline"
                  >
                    Re-Check Wallet
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(
                        "https://opensea.io/collection/takin-shotsnft"
                      );
                    }}
                    leftIcon={<ExternalLinkIcon />}
                    size="md"
                    height="48px"
                    width="200px"
                    border="2px"
                    borderColor={connected ? "green.500" : "orange.500"}
                    colorScheme={connected ? "teal" : "orange"}
                    variant="outline"
                  >
                    Purchase on OpenSea
                  </Button>
                </ButtonGroup>
              )
            ) : (
              <Button
                onClick={connectWallet}
                size="md"
                height="48px"
                width="200px"
                border="2px"
                borderColor={"orange.500"}
                isLoading={isLoading}
                loadingText="Connecting"
                colorScheme={"orange"}
                variant="outline"
              >
                {buttonTxt}
              </Button>
            )}
          </Stack>
        </CardFooter>
      </Card>
    </div>
  );
}
export default Connect;
