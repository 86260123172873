import {
  Box,
  Container,
  Heading,
  SimpleGrid,
  Icon,
  Text,
  Stack,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';




const features = [
    {id: 1, title: '1', text: 'All passes gain access to the exclusive alpha channels in Discord (just come check it out to see how were different!)'},
    {id: 2, title: '2', text:'On going LIVE Trading sessions, 1:1 personalized trade tuning and access to our locked library of all recorded trading videos and education!'},
    {id: 3, title: '3', text: 'The passes, again, are some badass grandma PFPs packed with INSANE traits and one of the main traits is your Shot Score trait. This will be a score between 1-7 which locks your NFT to that many entries to each giveaway. YES you can own more to stack your entries creating better odds of winning.'},
    {id: 4, title: '4', text: 'IRL benefits and meet ups'},
    {id: 5, title: '5', text: 'WL giveaways'},
    {id: 6, title: '6', text: 'GIVEAWAYS ON GIVEAWAYS ON GIVEAWAYS! 1-5% of my personal trading profits are given away!'},
    {id: 7, title: '7', text: 'Exclusive Holder Role Profit Share Pot'},
]

export default function Benefits() {
  return (
    <Box p={4}>
      <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={'3xl'}>What the passes will do for you!</Heading>
      </Stack>

      <Container maxW={'6xl'} mt={10}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
          {features.map((feature) => (
            <HStack key={feature.id} align={'top'}>
              <Box color={'green.400'} px={2}>
                <Icon as={CheckIcon} />
              </Box>
              <VStack align={'start'}>
                {/* <Text fontWeight={600}>{feature.title}</Text> */}
                <Text >{feature.text}</Text>
              </VStack>
            </HStack>
          ))}
        </SimpleGrid>
      </Container>
    </Box>
  );
}