import {
  Box,
  chakra,
  Container,
  Link,
  Stack,
  Img,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from '@chakra-ui/react';
import { FaInstagram, FaTwitter } from 'react-icons/fa';
import { ReactNode } from 'react';
import OpenSea from './imgs/icons8-opensea-96.svg';

const moment = require('moment');
const Logo = (props: any) => {
  return (
    <Text   
            fontWeight={800}
            >

      TAKIN'SHOTS
    </Text>
  );
};

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      cursor={'pointer'}
      as={'a'}
      href={href}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  return (
    <Box
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}
      >
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        spacing={4}
        justify={'center'}
        align={'center'}>
        <Logo />
        <Stack direction={'row'} spacing={6}>
          <Link href={'/'}>Home</Link>
          <Link href={'/library'}>Library</Link>
          <Link href={'/newsletter'}>Newsletter</Link>
        </Stack>
      </Container>

      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.700')}>
        <Container
          as={Stack}
          maxW={'6xl'}
          py={4}
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}>
          <Text>© {moment().year()} Takinshots.io | All rights reserved</Text>
          <Stack direction={'row'} spacing={6}>
            <SocialButton label={'Twitter'} href={'https://twitter.com/takinshots_'}>
              <FaTwitter />
            </SocialButton>
            <SocialButton label={'Instagram'} href={'https://www.instagram.com/takinshots1/'}>
              <FaInstagram />
            </SocialButton>
            <SocialButton label={'Opensea'} href={'https://opensea.io/collection/takin-shotsnft'}>
              <Img src={OpenSea} />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
