import { Heading } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ProductGrid } from "../components/ProductGrid";
import { ProductCard } from "../components/ProductCard";

function Shop() {
  const [product, setProduct] = useState([
    {
      id: "",
      product: {},
      unit_amount: 0,
      type: ""
    },
  ]);

  useEffect(() => {
    const getProduct = async () => {
      const item = await fetch("/api/getProducts").then((res) => res.json());
       setProduct(item.data)
    };
    getProduct();
  }, []);

  return (
    <>
      <Heading mb={10}>Shop</Heading>
      <ProductGrid>
        {Array.isArray(product) ? product.map((item) => (
          <ProductCard key={item.id} product={item} />
        )): null}
      </ProductGrid>
    </>
  );
}

export default Shop;