import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalHeader,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalFooter,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  useToast,
  Card,
  CardHeader,
  CardBody,
  Heading,
  Image,
  Stack,
  CardFooter,
  Text,
  Button,
  ButtonGroup,
  Stat,
  StatNumber,
  StatHelpText,
  CircularProgress, 
  CircularProgressLabel,
  Tooltip
} from "@chakra-ui/react";
import { SettingsIcon, PlusSquareIcon, Search2Icon, CloseIcon } from '@chakra-ui/icons';
import StripeUtils from "../model/StripeUtils";
import MandrillUtils from "../model/MandrillUtils";
import PortalLinkEmail from "../model/PortalLinkEmail";

function Newsletter() {
  const [message, setMessage] = useState<string>("");
  const [init, setInit] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
  const [product, setProduct] = useState<any>({});
  const [price, setPrice] = useState<any>({});
  const [email, setEmail] = useState<string>("");
  const domain = window.location.origin;
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const ProductDisplay = () => (
    <section>
      <Card
        direction={{ base: "column", sm: "row" }}
        variant="outline"
      >
        <Image
          loading={"lazy"}
          objectFit="cover"
          borderRadius="full"
          src={
            typeof product.images !== "undefined"
              ? product.images[0]
              : "https://"
          }
          alt={typeof product.name !== "undefined" ? product.name : "Loading"}
          fit={"fill"}
          align={"center"}
          maxW={{ base: "100%", sm: "25px", md: "70px", lg: "200px" }}
          h={{ base: "100%", sm: "25px", md: "70px", lg: "200px" }}
        />
        <Stack>
          <CardBody>
            <Heading size="md">
              {typeof product.name !== "undefined" ? product.name : "Loading"}
            </Heading>

            <Text py="2">{product.description}</Text>
            <Stat>
              {/* <StatLabel>Monthly Subscription</StatLabel> */}
              <StatNumber>
                {formatter.format(price.unit_amount / 100)}
              </StatNumber>
              <StatHelpText>Monthly Subscription</StatHelpText>
            </Stat>
            <Text color="blue.600" fontSize="2xl"></Text>
          </CardBody>

          <CardFooter>
            <ButtonGroup>
              <Button
                variant="solid"
                colorScheme="blue"
                onClick={createCheckoutSession}
                disabled={loading}
              >
                <PlusSquareIcon
                  fontSize={"2xl"}
                  style={{ marginRight: "2px" }}
                />
                Subscribe
              </Button>
              <Tooltip label="I close on click">
                <Button
                  variant="outline"
                  colorScheme="orange"
                  onClick={onOpen}
                  disabled={loading}
                >
                  <SettingsIcon
                    fontSize={"2xl"}
                    style={{ marginRight: "2px" }}
                  />{" "}
                  Manage
                </Button>
              </Tooltip>
            </ButtonGroup>
          </CardFooter>
        </Stack>
      </Card>
    </section>
  );
  const Message = () => (
    <section>
      <Card
        direction={{ base: "column", sm: "row" }}
        overflow="hidden"
        variant="outline"
        
      >
        <Image
          loading={"lazy"}
          objectFit="cover"
          borderRadius="full"
          src={
            typeof product.images !== "undefined"
              ? product.images[0]
              : "https://"
          }
          alt={typeof product.name !== "undefined" ? product.name : "Loading"}
          fit={"fill"}
          align={"center"}
          maxW={{ base: "100%", sm: "25px", md: "70px", lg: "200px" }}
          h={{ base: "100%", sm: "25px", md: "70px", lg: "200px" }}
        />

        <Stack>
          <CardBody>
            <Heading size="md">
              {typeof product.name !== "undefined" ? product.name : "Loading"}
            </Heading>

            <Text py="2">{product.description}</Text>
          </CardBody>

          <CardFooter>
            <ButtonGroup gap="4">
              <Button
                variant="outline"
                colorScheme="orange"
                onClick={onOpen}
                disabled={loading}
              >
                Manage Subscription
              </Button>
            </ButtonGroup>
          </CardFooter>
        </Stack>
      </Card>
    </section>
  );
 ;
  useEffect(() => {

    const getProduct = async () => {
      setLoading(true);

      //Setup the Stripe object
      const _StripeUtils = new StripeUtils(domain);

      //Grab the product
      const product = await _StripeUtils.retrieveProduct(
        process.env.REACT_APP_STRIPE_PRODUCT || ""
      );
  
      //Grab the default price of the product 
      const price = await _StripeUtils.retrievePrice(product.default_price);
  
        //Set the data for display
      setPrice(price);
      setProduct(product);

      //Stop loading
      setLoading(false);

      //Show the UI
      setInit(false);
    }


    //Grab the products and pricing data for the UI
    getProduct();

    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);


    //They subscribed
    if (query.get("success")) {
      setMessage("Order placed!");
      toast({
        title: "Subscription",
        description: message,
        status: "info",
        duration: 6000,
        isClosable: true,
      });
    }

    //They cancelled the action
    if (query.get("canceled")) {
      toast({
        title: "Subscription Cancelled",
        description: "Your Subscription action has been cancelled",
        status: "info",
        duration: 6000,
        isClosable: true,
      });
    }
  }, [domain, message, toast]);

  //Get the product from Stipe to display

  //create a checkout session so the user can subscribe to the newsletter
  const createCheckoutSession = async () => {
    setLoading(true);
    const _StripeUtils = new StripeUtils(domain);
    // window.location.href = await _StripeUtils.createCheckoutSession(
    //   process.env.REACT_APP_STRIPE_PRICE || ""
    // );
    window.location.href = await _StripeUtils.createCheckoutSession(product.default_price);
    
    //window.location.href = url;
  };

  //This function creates a portal session to manage the newsletter subscription
  const createPortalSession = async () => {

    //Disable the buttons
    setLoading(true);

    //Load the Stripe functions
    const _StripeUtils = new StripeUtils(domain);

    //Make sure the email address is valid
    if(email.length < 5 || !validateEmail(email)){
      setEmailIsValid(true);
      //Reject bad email addresses
    
      if(!toast.isActive('Invalid-Email-Address')){
        toast({
          id: 'Invalid-Email-Address',
          title: "Invalid Email Address",
          description: "Please enter a valid email address",
          status: "warning",
          duration: 6000,
          position: "top-right",
          isClosable: true,
        });
      }

      setLoading(false);
      return false;
    }
   
    //Email is valid, move forward and call the function to get a session URL
    const session = await _StripeUtils.createBillingPortalSession(email);
    //console.log("session URL:", session);
    //Make sure the session was created
    if(session.success === false){

      if(!toast.isActive('Subscription-Not-Found')){
        toast({
          id: 'Subscription-Not-Found',
          title: "Manage Subscription",
          description: `Your subscription could not be found using email address ${email}`,
          status: "warning",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
      }

      setLoading(false);
    }else{
      //We now need to email them
      //window.location.href = url.url;


    



      //We need the mandrill utils to send an email
      const _MandrillUtils = new MandrillUtils();

    
      //Make sure Mandrill is working
      if(await _MandrillUtils.run() === "PONG!"){

        //Build the contents of the email
        const _PortalLinkEmail = new PortalLinkEmail('Takin Shots Newsletter Subscription Management Link','Your Takin Shots Newsletter Subscription management link',session.url);
        const html = _PortalLinkEmail.html();

        //If all is good send them the email
        const emailed = await _MandrillUtils.emailHTML(html, email);

        if(emailed[0].status === "sent"){
        //Check the response to ensure the email was sent successfully
        toast({
          title: "Manage Subscription",
          description: `Subscription Management link sent to email address ${email}`,
          status: "info",
          duration: 6000,
          position: "top-right",
          isClosable: true,
        });
        }else{
                  //If mandrill is down we can't send the email
        toast({
          title: "Manage Subscription",
          description: `We had an issue sending a link to the email address ${email}`,
          status: "warning",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
        }

        onClose();

        //enable the buttons
        setLoading(false);

      }else{

        //If mandrill is down we can't send the email
        toast({
          title: "Manage Subscription",
          description: `We had an issue sending a link to the email address ${email}`,
          status: "warning",
          duration: 5000,
          position: "top-right",
          isClosable: true,
        });
      }
      setLoading(false);
    }
  };
  //This function validates the format of the email provided
  function validateEmail(email: string) {
    const emailRegex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return emailRegex.test(email);
  };
  return (
    <div>
      <Card
        align="center"
        style={{
          marginTop: "6em",
          marginBottom: "6em",
        }}
      >
        <CardHeader>
          <Heading size="md">Newsletter Subscription</Heading>
        </CardHeader>

        {/* If the UI is initially loading */}
        {init === true ? (
          <CardBody>
            <CircularProgress
              isIndeterminate
              color="blue.500"
              size="100px"
              thickness="4px"
            >
              <CircularProgressLabel>Loading</CircularProgressLabel>
            </CircularProgress>
          </CardBody>
        ) : (
          <CardBody>
            {message === "" ? <ProductDisplay /> : <Message />}
          </CardBody>
        )}
      </Card>
      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        isOpen={isOpen}
        onClose={onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage your subscription</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <Text>
              Please enter the email address associated with your Newsletter
              subscription.
            </Text>
            <hr style={{ marginTop: "15px", marginBottom: "15px" }} />
            <FormControl>
              <FormLabel>Email Address</FormLabel>
              <Input
                ref={initialRef}
                placeholder="john.smith@gmail.com"
                onChange={(e) => {
                  setEmailIsValid(false);
                  setEmail(e.target.value);
                }}
                errorBorderColor="red.300"
                isInvalid={emailIsValid}
                isRequired={true}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={createPortalSession}
              disabled={loading}
            >
              <Search2Icon style={{ marginRight: "5px" }} /> Manage
            </Button>
            <Button onClick={onClose} disabled={loading}>
              <CloseIcon style={{ marginRight: "5px" }} />
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
export default Newsletter;
